import * as React from 'react';
import {
  Button,
  Container,
  Divider,
  Grid,
  TextInputField,
  Typography,
  Seo,
  // SeoProps,
} from 'src/app/components';
import { useStyleContext } from 'src/app/context';
// import { CombinedAppProps, SeoObject } from 'src/app/types';

export default (props: any) => {
  const { children, location, pageContext, seo, ...passProps } = props;
  const { setAppFont } = useStyleContext();
  // Make Guard wrapper for pages which wont be rendered (and/or filter these pages out on build?
  if (
    typeof window === 'undefined' ||
    (window.location.hostname !== 'localhost' &&
      window.location.hostname !== 'travlr-dev.netlify.app') // TODO: .env
  ) {
    return null;
  }

  const [fontName, setFontName] = React.useState<string | null>(null);
  // https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap
  // const fontName = 'Bangers';
  // const fontFamilyParams = `${fontName}:wght@300;400;500;700&display=swap`;
  const fontFamilyParams = `${fontName}&display=swap`;
  const fontUrl = `https://fonts.googleapis.com/css2?family=${fontFamilyParams}`;

  const onChangeFont = () => {
    if (fontName) {
      setAppFont(fontName);
    }
    console.log('font changed done');
  };

  const onLoadFont = () => {
    if (fontName) {
      const link = document.createElement('link');
      link.rel = 'stylesheet'; // 'preload' or 'prefetch'?
      link.href = fontUrl;
      document.getElementsByTagName('head')[0].appendChild(link);
      // TODO: remove old link in meta?
    }
    console.log('font loaded done');
  };

  // React.useEffect(() => {
  //   if (fontName) {
  //     onChangeFont();
  //   }
  // }, [fontName]);

  const currentAppFontFamily = 'Noto Sans JP';

  const fontStyle = fontName ? { fontFamily: `"${fontName}", sans-serif` } : {};

  return (
    <Container>
      <Seo location={location} pageContext={pageContext} seo={seo} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextInputField
            fullWidth
            onChange={(e) => setFontName(e.target.value)}
            label={
              'Enter google font name (e.g: Noto+Sans+JP:wght@300;400;500;700&display=swap) - 300/400/500/700 are req'
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => onLoadFont()} fullWidth>
            Load preview
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => onChangeFont()} fullWidth>
            Switch app font
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {'Current font family:'}
                </Grid>
                <Grid item xs={12}>
                  {currentAppFontFamily || '-'}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h3'}>
                    Almost before we knew it, we had left the ground.
                  </Typography>
                  <Typography variant={'h1'}>h1. Heading</Typography>
                  <Typography variant={'h2'}>h2. Heading</Typography>
                  <Typography variant={'h3'}>h3. Heading</Typography>
                  <Typography variant={'h4'}>h4. Heading</Typography>
                  <Typography variant={'h5'}>h5. Heading</Typography>
                  <Typography variant={'h6'}>h6. Heading</Typography>
                  <Typography variant={'subtitle1'}>
                    subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                  <Typography variant={'subtitle2'}>
                    subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                  <Typography variant={'body1'}>
                    body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                  <Typography variant={'body2'}>
                    body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                  <Typography >button. SUBMIT ME</Typography>
                  <Typography display={'block'} variant={'caption'}>
                    caption. Said by Jesus
                  </Typography>
                  <Typography variant={'overline'}>overline. Overline text</Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                    sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
                    rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                    ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
                    sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                    dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                    et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                    takimata sanctus est Lorem ipsum dolor sit amet.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={'font-test'}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {'Preview font family:'}
                </Grid>
                <Grid item xs={12}>
                  {fontName || '-'}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h3'} style={fontStyle}>
                    Almost before we knew it, we had left the ground.
                  </Typography>
                  <Typography variant={'h1'} style={fontStyle}>
                    h1. Heading
                  </Typography>
                  <Typography variant={'h2'} style={fontStyle}>
                    h2. Heading
                  </Typography>
                  <Typography variant={'h3'} style={fontStyle}>
                    h3. Heading
                  </Typography>
                  <Typography variant={'h4'} style={fontStyle}>
                    h4. Heading
                  </Typography>
                  <Typography variant={'h5'} style={fontStyle}>
                    h5. Heading
                  </Typography>
                  <Typography variant={'h6'} style={fontStyle}>
                    h6. Heading
                  </Typography>
                  <Typography variant={'subtitle1'} style={fontStyle}>
                    subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                  <Typography variant={'subtitle2'} style={fontStyle}>
                    subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                  <Typography variant={'body1'} style={fontStyle}>
                    body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                  <Typography variant={'body2'} style={fontStyle}>
                    body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                  <Typography  style={fontStyle}>
                    button. SUBMIT ME
                  </Typography>
                  <Typography variant={'caption'} style={fontStyle} display={'block'}>
                    caption. Said by Jesus
                  </Typography>
                  <Typography variant={'overline'} style={fontStyle}>
                    overline. Overline text
                  </Typography>
                  <Typography style={fontStyle}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                    sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
                    rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                    ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
                    sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                    dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                    et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                    takimata sanctus est Lorem ipsum dolor sit amet.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
